import { api } from '@repo/core/config';
import { FinancialYear } from '@repo/core/models';

export const useFinancialYearService = () => {
  const get = async (parameters?: string): Promise<any> => {
    parameters = parameters || '';
    const options = { autoSave: true };
    try {
      const response = await api.get('financialYears' + parameters, { headers: options });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getById = async (id: string): Promise<FinancialYear> => {
    try {
      const response = await api.get('financialYears/' + id);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const post = async (data: FinancialYear[]): Promise<FinancialYear[]> => {
    try {
      const response = await api.post(`financialYears`, { data });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const addPrior = async (): Promise<FinancialYear[]> => {
    try {
      const response = await api.post(`financialYears/addPrior`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    get,
    getById,
    post,
    addPrior
  };
};
